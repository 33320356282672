import ky from "ky";

export let env = process.env;

export async function injectEnvsScript() {
  try {
    const envs = await ky.get("/envs.js").json();
    env = { ...env, ...envs };
  } catch {}
}
